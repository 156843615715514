import React, { useEffect, useRef, useState, useMemo } from "react";

const Toolbar = ({ onButtonClick }) => {
  const [active, setActive] = useState("Sistema");
  const indicatorRef = useRef(null);
  const buttonsRef = useRef([]);

  // Tabs para Sistema e Marketing
  const tabs = useMemo(() => ["Sistema", "Marketing", "Certificado Digital"], []);

  useEffect(() => {
    const activeButton = buttonsRef.current[tabs.indexOf(active)];
    const indicator = indicatorRef.current;

    if (activeButton && indicator) {
      const buttonWidth = activeButton.offsetWidth;
      const buttonLeft = activeButton.offsetLeft;

      indicator.style.width = `${buttonWidth}px`;
      indicator.style.transform = `translateX(${buttonLeft}px)`;
    }
  }, [active, tabs]);

  const handleClick = (option) => {
    setActive(option);
    if (onButtonClick) onButtonClick(option);
  };

  return (
    <nav className="toolbar">
      {tabs.map((tab, index) => (
        <button
          key={tab}
          ref={(el) => (buttonsRef.current[index] = el)}
          className={tab === active ? "active" : ""}
          onClick={() => handleClick(tab)}
        >
          {tab}
        </button>
      ))}
      <div ref={indicatorRef} className="indicator" />
    </nav>
  );
};

export default Toolbar;