import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isBetaListVisible, setIsBetaListVisible] = useState(false);

  const toggleBetaList = () => {
    setIsBetaListVisible(!isBetaListVisible);
  };

  return (
    <header className="header">
      <div className="header-left">
        <button
          className={`beta-span ${isBetaListVisible ? "open" : ""}`}
          onClick={toggleBetaList}
        >
          EM BETA
        </button>
        <button
          className={`beta-toggle ${isBetaListVisible ? "open" : ""}`}
          onClick={toggleBetaList}
        >
          {"∨"}
        </button>
        {isBetaListVisible && (
          <div className="beta-list">
            <ul>
              <li>
                <Link to="/idcast">IDCAST</Link>
              </li>
              <li>
                <Link to="/idgroup">IDGROUP</Link>
              </li>
              <li>
                <Link to="/idgame">IDGAME</Link>
              </li>
              <li>
                <Link to="/idacademy">IDACADEMY</Link>
              </li>
            </ul>
          </div>
        )}
      </div>  

      <div className="header-center">
        <Link to="/" className="home-button">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s"
            alt="Home"
          />
        </Link>
      </div>

      <div className="header-right">
        <a
          href="https://sejaparceiro.idbrasildigital.com.br/"
          className="seja-parceiro"
          target="_blank"
          rel="noopener noreferrer"
        >
          SEJA PARCEIRO
        </a>
      </div>
    </header>
  );
};

export default Header;