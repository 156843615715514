import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Componentes
import Header from "./components/Header";
import MainTitle from "./components/MainTitle";
import Toolbar from "./components/Toolbar";
import "./App.css";

// Páginas
import IdAcademy from "./pages/IdAcademy";
import IdCast from "./pages/IdCast";
import IdGroup from "./pages/IdGroup";
import IdGame from "./pages/IdGame";
import Planner from "./components/Planner";

// Layout
import Layout from "./components/Layout";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme === "dark" : true;
  });

  const location = useLocation();
  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("theme", newDarkMode ? "dark" : "light");
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const showToolbarRoutes = ["/", "/planner"];

  const renderPageTitle = () => {
    switch (location.pathname) {
      case "/idcast":
        return "Bem-vindo ao IDCAST";
      case "/idgroup":
        return "Bem-vindo ao IDGROUP";
      case "/idgame":
        return "Bem-vindo ao IDGAME";
      case "/idacademy":
        return "Bem-vindo ao IDACADEMY";
      default:
        return null;
    }
  };

  return (
    <div className={`app ${darkMode ? "dark-mode" : "light-mode"}`}>
      <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      {location.pathname === "/" && <MainTitle />}

      {showToolbarRoutes.includes(location.pathname) && (
        <Toolbar onButtonClick={setSelectedOption} />
      )}

      <div className="main-content">
        {location.pathname !== "/" && (
          <h1 className="page-title">{renderPageTitle()}</h1>
        )}

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Planner selectedOption={selectedOption} />} />
          </Route>
          <Route path="/idcast" element={<IdCast />} />
          <Route path="/idgroup" element={<IdGroup />} />
          <Route path="/idgame" element={<IdGame />} />
          <Route path="/idacademy" element={<IdAcademy />} />
        </Routes>
      </div>

      <footer className="footer">© 2024 Seja IDX. Todos os direitos reservados.</footer>

      {/* Botão de alternância de tema fora do Header */}
      <button className="toggle-theme" onClick={toggleDarkMode}>
        <img
          src={
            darkMode
              ? "https://cdn-icons-png.flaticon.com/512/414/414891.png"
              : "https://cdn-icons-png.flaticon.com/128/5311/5311069.png"
          }
          alt="Alternar Tema"
        />
      </button>
    </div>
  );
}

export default App;