import React, { useState } from "react";

const Planner = ({ selectedOption }) => {
  const [subView, setSubView] = useState(null);

  const handleButtonClick = (button) => {
    setSubView(button);
  };

  const handleBackClick = () => {
    setSubView(null);
  };

  const buttonData = {
    "Sistema": [
      { id: "Websites",
        title: "Websites",
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.", 
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Landing Pages", 
        title: "Landing Pages", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Ecommerce", 
        title: "Ecommerce", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "CRM", 
        title: "CRM", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Sistema de NF-e", 
        title: "Sistema de NF-e", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "ERP", 
        title: "ERP", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",       
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "PontoMais", 
        title: "PontoMais", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Inteligência Artificial", 
        title: "Inteligência Artificial", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
    "Marketing": [
      { id: "Identidade Visual", 
        title: "Identidade Visual", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Gestão de Redes Sociais", 
        title: "Gestão de Redes Sociais", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Tráfego Pago", 
        title: "Tráfego Pago", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Design", 
        title: "Design", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Consultoria", 
        title: "Consultoria", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Profissionalização", 
        title: "Profissionalização", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Audiovisual", 
        title: "Audiovisual", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Experiência 360 Graus", 
        title: "Experiência 360 Graus", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Google Meu Negócio", 
        title: "Google Meu Negócio", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "SEO", 
        title: "SEO", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Funil de Vendas", 
        title: "Funil de Vendas", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
    "Certificado Digital": [
      { id: "Botão1", 
        title: "Botão1", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão2", 
        title: "Botão2",
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão3", 
        title: "Botão3", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão4", 
        title: "Botão4", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão5", 
        title: "Botão5", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },

      { id: "Botão6", 
        title: "Botão6", 
        content: "Lorem ipsum dolor sit amet. Quo sint enim aut quod perspiciatis ut aspernatur ipsum. Aut impedit necessitatibus vel rerum dicta ut animi libero qui dolor reprehenderit. Eos quia dignissimos non galisum maiores in sint beatae. Ad necessitatibus consequatur ut maiores ipsam ea voluptas soluta cum galisum molestiae vel veniam repudiandae id maiores sapiente. Et nesciunt amet non sint debitis et ullam explicabo qui quisquam nihil. Eos illo explicabo vel doloremque explicabo quo nostrum quod. Sit maiores ipsam et maxime dolor quo quaerat dolorem et nobis veniam sed ratione labore aut iure quibusdam. Eum quam omnis ut quas dolorem aut accusantium consequatur aut voluptate voluptate ut distinctio voluptatum. Sit itaque necessitatibus et cumque officia in rerum dolores non dolor maxime non Quis ipsam eos aliquam minima? Non illum perspiciatis At nesciunt voluptatum ut suscipit quia et error alias qui itaque quisquam. Aut asperiores suscipit ea laboriosam harum vel nulla minus ut voluptas saepe et rerum vitae a galisum sunt et delectus placeat. Id voluptas aliquam vel nemo iusto ut consequatur consequatur et sint omnis. Qui consequuntur quam ut placeat iure est cumque optio. Est odio quia ad omnis eveniet aut nobis blanditiis est fuga dolores qui aliquid illo et corrupti molestiae et excepturi esse.",        
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcZquvxPxQwTor0a0PP_zb5YvXVj9kiJjZlA&s", 
        video: "" },
    ],
  };

  // Função para renderizar os botões dependendo da opção selecionada
  const renderButtons = () => {
    const buttons = buttonData[selectedOption];
    return buttons.map((button) => (
      <button
        key={button.id}
        className={`planner-button planner-button-${button.id.replace(/\s+/g, '-')} show-img`} 
        onClick={() => handleButtonClick(button)}
      >
        <p className="button-title">{button.title}</p>
      </button>
    ));
  };

  const renderContent = () => {
    if (!subView) {
      return (
        <>
          <h2>Opções para {selectedOption}</h2>
          <div className="button-grid">{renderButtons()}</div>
        </>
      );
    } else {
      return (
        <>
          <button className="back-button" onClick={handleBackClick}>
            Voltar
          </button>
          <div className="subview-content">
            <div className="subview-left">
              <h3>{subView.title}</h3>
              <p>{subView.content}</p>
            </div>
            <div className="subview-right">
              <img src={subView.img} alt={subView.title} className="subview-img" />
              {subView.video && (
                <video controls className="subview-video">
                  <source src={subView.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <section className="planner">
      {selectedOption ? renderContent() : <p>Selecione uma opção para ver o conteúdo.</p>}
    </section>
  );
};

export default Planner;